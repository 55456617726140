<template>
  <b-overlay :show="loadingBookings" rounded="sm" variant="white">
    <b-card title="Llistat de reserves">
      <accommodation-bookings-table
        :bookings="mappedBookings"
        :loading="loadingBookings"
        show-search
      />
    </b-card>
    <b-card title="Dades fiscals">
      <b-row>
        <b-col cols="12" md="4" class="mb-1 mb-md-0">
          <flat-pickr
            v-model="reportDatesRangeInput"
            class="form-control"
            :config="rangepickerConfig"
            placeholder="Dates d'entrada"
            @on-change="onDatesRangeChanged"
          />
        </b-col>
        <b-col cols="12" md="auto">
          <b-button
            :disabled="isGenerateReportButtonDisabled"
            variant="primary"
            block
            @click="generateBookingsReport"
          >
            Descarrega
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BRow, BCol, BCard, BButton } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { Catalan } from "flatpickr/dist/l10n/cat.js";
import { saveAs } from "file-saver";
import AccommodationBookingsTable from "@/views/accommodations/accommodation/components/AccommodationBookingsTable.vue";
import { formatDateObjectToDatabaseDate } from "@/utils/formatters";
import { generateBookingsListTaxReport, notifyError, getSimpleGuestsText } from "@/utils/methods";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    AccommodationBookingsTable,
    flatPickr,
    BButton,
  },
  data() {
    return {
      reportDatesRangeInput: null,
      reportDatesRange: {
        from: null,
        to: null,
      },
      rangepickerConfig: {
        mode: "range",
        locale: Catalan,
        altFormat: "d/m/Y",
        altInput: true,
      },
    };
  },
  computed: {
    loadingBookings() {
      return this.$store.getters["accommodation/loadingBookings"];
    },
    bookings() {
      return this.$store.getters["accommodation/bookings"];
    },
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    contract() {
      return this.$store.getters["accommodation/contract"];
    },
    owner() {
      return this.contract?.owner || null;
    },
    coOwners() {
      return this.contract?.coOwners || [];
    },
    mappedBookings() {
      if (this.bookings.length > 0) {
        return this.bookings.map((booking) => ({
          uuid: booking.uuid || this.$t("No definit"),
          date: booking.date || this.$t("No definit"),
          localizator: booking.localizator || this.$t("No definit"),
          status: booking.status || this.$t("No definit"),
          source: booking.source || this.$t("No definit"),
          client: booking.client?.fullName || this.$t("No definit"),
          clientUuid: booking.client?.uuid || null,
          checkin: booking.checkin || this.$t("No definit"),
          checkout: booking.checkout || this.$t("No definit"),
          highlighted: booking.highlighted || false,
          fictitious: booking.fictitious || false,
          guests: this.bookingGuests(booking) || this.$t("No definit"),
        }));
      }
      return [];
    },
    isGenerateReportButtonDisabled() {
      return !this.reportDatesRange.from || !this.reportDatesRange.to;
    },
  },
  methods: {
    onDatesRangeChanged(selectedDates) {
      if (selectedDates?.length !== 2) return;
      this.reportDatesRange.from = formatDateObjectToDatabaseDate(
        selectedDates[0]
      );
      this.reportDatesRange.to = formatDateObjectToDatabaseDate(
        selectedDates[1]
      );
    },
    generateBookingsReport() {
      try {
        const { from, to } = this.reportDatesRange || {};

        // NO BOOKINGS SELECTED WARNING
        if (!this.bookings?.length || !from || !to) {
          this.$swal({
            title: "Sense reserves",
            text: "No hi ha reserves per el període seleccionat",
            confirmButtonText: "D'acord",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          return;
        }

        this.$store.dispatch("app/setLoading", true);

        const bookingsReportText = generateBookingsListTaxReport({
          bookings: this.bookings,
          startDate: from,
          endDate: to,
          owner: this.owner || null,
          coOwners: this.coOwners || null,
        });

        if (!bookingsReportText) {
          throw new Error("The bookingsReportText could not be generated");
        }

        const blob = new Blob([bookingsReportText], {
          type: "text/plain;charset=utf-8",
        });

        const filename = ["dades_fiscals"];
        if (this.accommodation?.name) {
          filename.push(
            this.accommodation.name.toLowerCase().replace(" ", "_")
          );
        }
        if (this.reportDatesRange.from) {
          filename.push(this.reportDatesRange.from);
        }
        if (this.reportDatesRange.to) {
          filename.push(this.reportDatesRange.to);
        }

        saveAs(blob, `${filename.join("_")}.csv`);
        this.reportDatesRangeInput = null;
      } catch (error) {
        notifyError(
          "Error d'exportació",
          "Hi ha hagut algun error durant la generació del fitxer"
        );
      } finally {
        this.$store.dispatch("app/setLoading", false);
      }
    },
    bookingGuests(booking) {
      return getSimpleGuestsText({
        adults: booking.adults,
        children: booking.children,
        babies: booking.babies,
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
